import { HydratedComponent } from ".";

export function getCookie(cname: string) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function hydrateComponent<Args, Props>(component: HydratedComponent<Args, Props>) {
  return (id: unknown) => {
    if (typeof id !== "string") {
      throw new Error("id is not of type string");
    }
    const elContent = document?.getElementById(id)?.textContent;
    if (typeof elContent !== "string") {
      throw new Error(`Could not read json from element with id: ${id}`);
    }
    const data = JSON.parse(elContent) as Args;
    return component(data);
  };
}

export default {
  getCookie,
  hydrateComponent,
};
